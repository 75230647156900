
export default {
  name: 'NuxtClientOnly',
  render(createElement) {
    return createElement(
      this.tag,
      this.$slots.default,
    );
  },
  computed: {
    tag() {
      return (process.client || process.server) ? 'client-only' : 'div';
    },
  },
};
